<template>
  <div id="app">
    <Drops />
    <div class="root-container">
      <Landing /> 
      <div v-for="(content, index) in contents" :key="index" class="content-wrapper" :class="aligns[index]">
        <Content :content="content" :align="aligns[index]" />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import { inject, reactive, computed } from 'vue'
import Landing from '@/components/Landing.vue'
import Drops from '@/components/Drops.vue'
import Content from '@/components/Content.vue'
import Footer from '@/components/Footer.vue'
import './styles/index.scss'

export default {
  name: 'App',
  components: {
    Landing,
    Drops,
    Content,
    Footer
  },
  setup() {
    const mq = inject('mq');
    const contents = reactive([
      {
        title: 'Blockchain Toolkit',
        tagline: 'develop, debug, test, deploy, track and manage your blockchain applications',
        links: [
          {
            name: 'github',
            url: ''
          },
          {
            name: 'vs code extension',
            url: ''
          },
          {
            name: 'quick start',
            url: ''
          }
        ],
        align: 'left',
        icon: require('@/assets/svgs/blockchain-toolkit.svg'),
        iconPcHeight: 272,
        pcShift: 136,
        iconMobileHeight: 56,
      },
      {
        title: 'Smart Contract Debugger',
        tagline: 'debugger extension for neo smart contracts',
        links: [
          {
            name: 'github',
            url: ''
          },
          {
            name: 'vs code extension',
            url: ''
          },
          {
            name: 'design notes',
            url: ''
          }
        ],
        align: 'right',
        icon: require('@/assets/svgs/smart-contract-debugger.svg'),
        iconPcHeight: 390,
        pcShift: 100,
        iconMobileHeight: 84
      },
      {
        title: 'Visual DevTracker',
        tagline: 'Blockchain explorer that is directly available within vs code',
        links: [
          {
            name: 'github',
            url: ''
          },
          {
            name: 'vs code extension',
            url: ''
          },
          {
            name: 'design notes',
            url: ''
          }
        ],
        align: 'left',
        icon: require('@/assets/svgs/visual-devtracker.svg'),
        iconPcHeight: 448,
        pcShift: 112,
        iconMobileHeight: 84
      },
      {
        title: 'Private Net',
        tagline: 'Standalone instance of various blockchains',
        links: [
          {
            name: 'github',
            url: ''
          },
          {
            name: 'quick start',
            url: ''
          },
          {
            name: 'design notes',
            url: ''
          }
        ],
        align: 'right',
        icon: require('@/assets/svgs/private-net.svg'),
        iconPcHeight: 324,
        pcShift: 206,
        iconMobileHeight: 70
      },
      {
        title: 'Enterprise Toolkit',
        tagline: 'model, design, and develop standard-based artifacts based on the iwa ttf',
        links: [
          {
            name: 'github',
            url: ''
          },
          {
            name: 'vs code extension',
            url: ''
          },
          {
            name: 'iwa ttf',
            url: ''
          }
        ],
        align: 'left',
        icon: require('@/assets/svgs/enterprise-toolkit.svg'),
        iconPcHeight: 197,
        pcShift: 196,
        iconMobileHeight: 42
      },
      {
        title: 'Visual Digital Asset Designer',
        tagline: 'vs code extension that facilitates manipulation of artifacts in the ttf',
        links: [
          {
            name: 'github',
            url: ''
          },
          {
            name: 'vs code extension',
            url: ''
          }
        ],
        align: 'right',
        icon: require('@/assets/svgs/visual-token-designer.svg'),
        iconPcHeight: 288,
        pcShift: 0,
        iconMobileHeight: 62
      }
    ])

    const aligns = computed(() => {
      return contents.map(content => {
        return mq.value === 'mobile' ? 'mobile' : content.align
      })
    })

    const ngd = reactive({
      name: 'ngd enterprise',
      url: ''
    })

    return {
      contents,
      aligns,
      ngd
    }
  }
}
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.root-container {
  display: flex;
  flex-direction: column;
  width: 88vw;
  max-width: 1440px;
}
</style>
